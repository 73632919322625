<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Editor from '@tinymce/tinymce-vue'
// import router from "@/router/index.js";
//import Multiselect from "vue-multiselect";
//import CKEditor from "@ckeditor/ckeditor5-vue";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
//import Pagination from '../../../components/Pagination.vue'
//import { format } from "date-fns";
/**
 * Orders Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        Editor,
        // Pagination
        //Multiselect,
        //ckeditor: CKEditor.component,
    },
    data() {
        return {
            title: "Annexe web",
            fields: [
                { key: "title", label: "Question" },
                { key: "content", sortable: true, label: "Réponse" },
                { key: "action", sortable: true, label: "Action", },
            ],
            fieldsP: [
                { key: "title", label: "Partenaire" },
                { key: "content", sortable: true, label: "Description" },
                { key: "action", sortable: true, label: "Action", },
            ],
            inputs: {
                type: '',
                title: "",
                content: "",
                image: "",
                lang: ""

            },
            inputsFaq: {
                type: '',
                title: "",
                content: "",
                image: "",
                lang: ""

            },
            inputsCgu: {
                type: '',
                title: "",
                content: "",
                image: "",
                lang: ""

            },
            inputsPartenaire: {
                type: '',
                title: "",
                url:"",
                content: "",
                image: "",
                lang: ""

            },
            currentPage: 1,
            perPage: 10,
            currentPageP: 1,
            perPageP: 10,
            pcData: [],
            partenaireData: [],
            faqData: [],
            cguData: [],
            editFaqModal: false,
            oneFaq: {
                id: 0,
                title: "",
                content: "",
            },
            file:null,

        }
    },

    computed: {

    },
    async mounted() {
        // await   this.dataMap()

        await this.getPc();
        await this.getFaq();
        await this.getCgu();
        await this.getPartenaire();
    },
    created() {
        //this.dataMap()
    },
    methods: {



        async addPc() {
            this.inputs.type = "pc"

            const req = await apiRequest('POST', 'add-annexe', this.inputs)
            console.log("req", req)
            if (req.status == 200) {
                console.log("done")
                const response = req.data
                this.$toast.success('Opération réussie avec succès')
                console.log(response)
            }
        },
        async addFaq() {
            this.inputsFaq.type = "faq"

            const req = await apiRequest('POST', 'add-annexe', this.inputsFaq)
            console.log("req", req)
            if (req.status == 200) {
                console.log("done")
                const response = req.data
                this.$toast.success('Opération réussie avec succès')
                this.getFaq()
                console.log(response)
            }
        },
        async addPartenaire() {
            this.inputsPartenaire.type = "partenaire"

            const formData = new FormData;
            formData.append('title', this.inputsPartenaire.title)
            formData.append('type', this.inputsPartenaire.type)
            //formData.append('url', this.inputsPartenaire.url)
            formData.append('content', this.inputsPartenaire.content)
            formData.append('image', this.file)
            

            const req = await apiRequest('POST', 'add-annexe', formData)
            console.log("req", req)
            if (req.status == 200) {
                console.log("done")
                //const response = req.data
                this.$toast.success('Opération réussie avec succès')
                this.getPartenaire()
                //console.log(response)
            }
        },
        async addCgu() {
            this.inputsCgu.type = "cgu"

            const req = await apiRequest('POST', 'add-annexe', this.inputsCgu)
            console.log("req", req)
            if (req.status == 200) {
                console.log("done")
                const response = req.data
                this.$toast.success('Opération réussie avec succès')
                console.log(response)
            }
        },

        async getPc() {


            const req = await apiRequest('GET', `annexe-list?type=pc`)
            console.log("req", req)
            if (req.status == 200) {

                const response = req.data
                console.log("done", response)
                this.pcData = response[0];
                //this.$toast.success('Opération réussie avec succès')
                this.inputs.title = this.pcData.title
                this.inputs.content = this.pcData.content
                console.log("pcData récupérée", this.pcData)
            }
        },
        async getFaq() {
            this.faqData

            const req = await apiRequest('GET', `annexe-list?type=faq`)
            console.log("req", req)
            if (req.status == 200) {

                const response = req.data
                console.log("done", response)

                response.map(item => this.faqData.push(item))

                //this.$toast.success('Opération réussie avec succès')

                console.log("faqData récupérée", this.faqData)
            }
        },
        editModalOpener(item) {
            console.log("item", item)

            this.oneFaq.title = item.title;
            this.oneFaq.content = item.content;
            this.oneFaq.id = item.id;
            this.editFaqModal = true
        },

        handleFileUpload() {
            this.file = this.$refs.file.files[0];

        },

        async editFaq() {
            console.log("inside of it")
            const req = await apiRequest('PUT', `annexe-update`, { id: this.oneFaq.id, title: this.oneFaq.title, content: this.oneFaq.content });
            //console.log("req", req);
            if (req.status == 200) {

                this.$toast.success("Opération réussie");
                this.editFaqModal = false;
                this.getFaq();
                //this.$toast.success('Opération réussie avec succès')


            }

        },
        async getCgu() {


            const req = await apiRequest('GET', `annexe-list?type=cgu`)
            console.log("req", req)
            if (req.status == 200) {

                const response = req.data
                console.log("done", response)
                this.cguData = response[0];
                this.inputsCgu.title = this.cguData.title
                this.inputsCgu.content = this.cguData.content
                //this.$toast.success('Opération réussie avec succès')
                console.log("cguData récupérée", this.cguData)
            }
        },


        async getPartenaire() {


            const req = await apiRequest('GET', `annexe-list?type=partenaire`)
            console.log("req", req)
            if (req.status == 200) {

                const response = req.data
                response.map(item => this.partenaireData.push(item))

                //this.$toast.success('Opération réussie avec succès')

                console.log("faqData récupérée", this.partenaireData)
            }
        },




    },
};
</script>
<template>
    <Layout>
        <PageHeader />
        <div class="row rowBase">
            <div class="col-lg-12 ">
                <div class="card p-4 ">
                    <div class="card-body">

                        <b-tabs pills card content-class="mt-3" justified>
                            <b-tab active class="" title="Politique de confidentialité">

                                <h4 class="mb-4 fs-large fw-bold">
                                    Politique de confidentialité
                                </h4>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <form>

                                            <div class="form-group">
                                                <label for="" class="form-label">Titre</label>
                                                <input type="text" v-model="inputs.title" placeholder="Titre"
                                                    class="form-control">
                                            </div>

                                            <div class="form-group">

                                                <label for="content"></label>

                                                <Editor id="content"
                                                    api-key="6z7bo0jx43facf9rinxcqklzjsqcjggj14blvingkobdtuz8" :init="{
                                                    toolbar_mode: 'sliding',
                                                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
                                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                                }" :initial-value="pcData.content" v-model="inputs.content"
                                                    class="form-control" style="height: 600px;" />



                                            </div>


                                            <div class="d-flex justify-content-end">

                                                <a @click.prevent="addPc" class="btn btn-success">Ajouter</a>

                                            </div>

                                        </form>



                                    </div>

                                </div>

                            </b-tab>
                            <b-tab class="" title="Questions fréquentes ">

                                <h4 class="mb-4 fs-large fw-bold">
                                    Questions fréquentes !
                                </h4>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <form>

                                            <div class="form-group">
                                                <label for="" class="form-label">Titre</label>
                                                <input type="text" v-model="inputsFaq.title" placeholder="Titre"
                                                    class="form-control">
                                            </div>

                                            <div class="form-group">

                                                <label for="content-faq"></label>

                                                <Editor id="content-faq"
                                                    api-key="6z7bo0jx43facf9rinxcqklzjsqcjggj14blvingkobdtuz8" :init="{
                                                    toolbar_mode: 'sliding',
                                                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
                                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                                }" initial-value="Réponse ici" v-model="inputsFaq.content"
                                                    class="form-control" style="height: 600px;" />



                                            </div>


                                            <div class="d-flex justify-content-end">

                                                <a @click.prevent="addFaq" class="btn btn-success">Ajouter</a>

                                            </div>

                                        </form>



                                    </div>

                                    <div class="col-lg-6">
                                        <div class="col-lg-12 ">
                                            <div class="card p-4 ">




                                                <b-tabs pills card content-class="mt-3" justified>

                                                    <b-tab active class="" title="Listes des questions fréquentes">

                                                        <b-pagination v-model="currentPage" :total-rows="faqData.length"
                                                            :per-page="perPage" aria-controls="my-table"
                                                            :limit="1"></b-pagination>

                                                        <!-- <p class="mt-3">Current Page: {{ currentPage }}</p>
                                                    <p class="mt-3">faqData.length: {{ faqData.length }}</p> -->


                                                        <b-table id="my-table" striped responsive :items="faqData"
                                                            :fields="fields" :per-page="perPage"
                                                            :current-page="currentPage">
                                                            <template #cell(action)="row">
                                                                <div class="d-flex justify-content-between">
                                                                    <a href="#"
                                                                        @click.prevent="editModalOpener(row.item)"><i
                                                                            class="fas fa-pen md text-info"></i></a>

                                                                    <a href="#" @click.prevent="delItem(row.item)"><i
                                                                            class="fas fa-trash md text-danger"></i></a>
                                                                </div>
                                                            </template>

                                                            <template #cell(content)="row">
                                                                <span v-html="row.value">

                                                                </span>
                                                            </template>
                                                        </b-table>

                                                    </b-tab>

                                                </b-tabs>











                                            </div>
                                        </div>
                                    </div>

                                </div>



                            </b-tab>
                            <b-tab class="" title="CGU">

                                <h4 class="mb-4 fs-large fw-bold">
                                    Conditions générales
                                </h4>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <form>
                                            <div class="form-group">
                                                <label for="" class="form-label">Titre</label>
                                                <input type="text" v-model="inputsCgu.title" placeholder="Titre"
                                                    class="form-control">
                                            </div>

                                            <div class="form-group">

                                                <label for="content-3"></label>

                                                <Editor id="content-3"
                                                    api-key="6z7bo0jx43facf9rinxcqklzjsqcjggj14blvingkobdtuz8" :init="{
                                                    toolbar_mode: 'sliding',
                                                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
                                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                                }" :initial-value="cguData.content
                                                    " v-model="inputsCgu.content" class="form-control" />



                                            </div>


                                            <div class="d-flex justify-content-end">

                                                <a @click.prevent="addCgu" class="btn btn-success">Ajouter</a>

                                            </div>

                                        </form>



                                    </div>

                                </div>

                            </b-tab>
                            <b-tab class="" title="Partenaires">

                                <h4 class="mb-4 fs-large fw-bold">
                                    Partenaires
                                </h4>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <form>

                                            <div class="form-group">
                                                <label for="" class="form-label">Titre</label>
                                                <input type="text" v-model="inputsPartenaire.title" placeholder="Titre"
                                                    class="form-control">
                                            </div>

                                            <div class="form-group">

                                                <label for="content-Partenaire">Description</label>

                                                <Editor id="content-Partenaire"
                                                    api-key="6z7bo0jx43facf9rinxcqklzjsqcjggj14blvingkobdtuz8" :init="{
                                                    toolbar_mode: 'sliding',
                                                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
                                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                                }" initial-value="Description ici..."
                                                    v-model="inputsPartenaire.content" class="form-control"
                                                    style="height: 600px;" />



                                            </div>
                                            <!-- <div class="form-group">
                                                <label for="" class="form-label">Lien vers </label>
                                                <input type="text" v-model="inputsPartenaire.url" placeholder="Titre"
                                                    class="form-control">
                                            </div> -->
                                            <div class="form-group">
                                                <input type="file" ref="file" @change="handleFileUpload" class="form-control">
                                            </div>


                                            <div class="d-flex justify-content-end">

                                                <a @click.prevent="addPartenaire" class="btn btn-success">Ajouter</a>

                                            </div>

                                        </form>



                                    </div>

                                    <div class="col-lg-6">
                                        <div class="col-lg-12 ">
                                            <div class="card p-4 ">




                                                <b-tabs pills card content-class="mt-3" justified>

                                                    <b-tab active class="" title="Listes des partenaires">

                                                        <b-pagination v-model="currentPage"
                                                            :total-rows="partenaireData.length" :per-page="perPage"
                                                            aria-controls="p-table" :limit="1"></b-pagination>
                                                        <b-table id="p-table" striped responsive :items="partenaireData"
                                                            :fields="fieldsP" :per-page="perPageP"
                                                            :current-page="currentPageP">
                                                            <template #cell(action)="row">
                                                                <div class="d-flex justify-content-between">
                                                                    <a href="#"
                                                                        @click.prevent="editModalOpener(row.item)"><i
                                                                            class="fas fa-pen md text-info"></i></a>

                                                                    <a href="#" @click.prevent="delItem(row.item)"><i
                                                                            class="fas fa-trash md text-danger"></i></a>
                                                                </div>
                                                            </template>

                                                            <template #cell(content)="row">
                                                                <span v-html="row.value">

                                                                </span>
                                                            </template>
                                                        </b-table>

                                                    </b-tab>

                                                </b-tabs>











                                            </div>
                                        </div>
                                    </div>

                                </div>



                            </b-tab>

                        </b-tabs>


                    </div>
                </div>
            </div>
        </div>

        <b-modal no-enforce-focus id="someModal" v-model="editFaqModal" hide-footer size="lg">


            <div class="p-3">
                <form>

                    <div class="form-group">
                        <label for="" class="form-label">Titre</label>
                        <input type="text" v-model="oneFaq.title" placeholder="Titre" class="form-control">
                    </div>

                    <div class="form-group">

                        <label for="content-faq-edit"></label>




                        <Editor id="content-faq-edit" api-key="6z7bo0jx43facf9rinxcqklzjsqcjggj14blvingkobdtuz8" :init="{
                                                    toolbar_mode: 'sliding',
                                                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
                                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                                }" :initial-value="oneFaq.content" v-model="oneFaq.content"
                            style="height: 500px;" />




                    </div>


                    <div class="d-flex justify-content-end">

                        <button @click.prevent="editFaq()" class="btn btn-success">Modifier</button>

                    </div>

                </form>
            </div>


        </b-modal>
    </Layout>
</template>
<style>
.v-dialog__content {
    z-index: 203 !important;
}

.logo {
    display: block;
    margin: 0 auto 2rem;
}

@media (min-width: 1024px) {
    #sample {
        display: flex;
        flex-direction: column;
        place-items: center;
        width: 1000px;
    }
}
</style>
